<script setup lang="ts">
import ShiftMemberSetForm from '@app/components/shifts/shifts/forms/ShiftMemberSetForm.vue'
import RoundButton from '@app/components/ui/button/RoundButton.vue'
import { useUserCan } from '@app/composables/useUserCan'
import type { Shift } from '@app/types/shifts'
import { UserPlusIcon } from '@heroicons/vue/24/solid'
import { type Component, type ShallowRef, computed, inject } from 'vue'

const { shift } = defineProps<{ shift: Shift }>()

const activeButton = inject<ShallowRef<Component>>('activeButton')
const { can } = useUserCan()

const show = computed(() => can('shifts.shift.set-members') && shift.application.slug !== 'briggs-and-walker')
</script>

<template>
    <RoundButton v-if="show" :label="$t('shifts.members')" @click="activeButton = ShiftMemberSetForm">
        <UserPlusIcon class="size-5" aria-hidden="true" />
    </RoundButton>
</template>
