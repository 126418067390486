<script setup lang="ts">
import type { ShiftAvailability } from '@app/types/shifts'
import { format } from 'date-fns'
import { computed } from 'vue'

const { availability } = defineProps<{
    availability: ShiftAvailability
}>()

const emit = defineEmits(['event:clicked'])

const from = computed(() => format(new Date(availability.from), 'HH:mm'))
const to = computed(() => format(new Date(availability.to), 'HH:mm'))
const title = () => {
    return availability.work_type_slug === 'sales-agent'
        ? availability.client_project_name + ' / ' + availability.location_type_slug
        : availability.client_project_name
}
</script>

<template>
    <div class="relative flex w-full flex-1 overflow-hidden transition-all duration-300 hover:brightness-90" @click="emit('event:clicked')">
        <div
            class="group mx-1 flex w-full cursor-pointer gap-2 whitespace-nowrap rounded-lg px-1 py-1 text-xs font-medium uppercase scrollbar-hide"
            :class="['bg-' + availability.client_color_code + '-50 text-' + availability.client_color_code + '-700']"
            :title="availability.client_project_name + ' - ' + availability.location_type_name + ' - ' + from + ' to ' + to"
        >
            <div class="left-2 h-full w-[5px] rounded" :class="availability.has_shift ? ['bg-' + availability.client_color_code + '-500'] : ''"></div>
            <div class="flex flex-col">
                <time class="flex flex-col font-light">{{ from }} - {{ to }}</time>
                <p class="text-ellipsis">{{ title() }}</p>
                <p class="text-ellipsis font-light italic">{{ availability.branch_name }}</p>
            </div>
        </div>
    </div>
</template>
