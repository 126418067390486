<script lang="ts" setup>
import ContractSignForm from '@app/components/contracts/sign/ContractSignForm.vue'
import Button from '@app/components/ui/button/Button.vue'
import Dropdown from '@app/components/ui/dropdown/Dropdown.vue'
import Form from '@app/components/ui/form/Form.vue'
import FormActions from '@app/components/ui/form/FormActions.vue'
import FormField from '@app/components/ui/form/FormField.vue'
import FormSection from '@app/components/ui/form/FormSection.vue'
import Input from '@app/components/ui/form/Input.vue'
import type { FormStep } from '@app/components/ui/multiStepForm/types'
import { useGooglePlacesAutocomplete } from '@app/composables/useGooglePlacesAutcomplete'
import { useModelSelect } from '@app/composables/useModelSelect'
import Guest from '@app/layouts/Guest.vue'
import type { Country } from '@app/types/shared'
import { translatedSelectPlaceholder } from '@app/utils/form'
import { useForm } from '@inertiajs/vue3'
import VueDatePicker from '@vuepic/vue-datepicker'
import { formatISO } from 'date-fns'
import { computed, watch, watchEffect } from 'vue'

defineOptions({
    layout: Guest,
})

type PersonalInformationModel = {
    first_name: string
    last_name: string
    email: string
    phone_number: string
    date_of_birth: string | null
    place_of_birth: string | null
    street: string | null
    house_number: string | null
    house_number_suffix: string | null
    postal_code: string | null
    place_of_residence: string | null
    country_id: number | null
}

const props = defineProps<{
    steps: FormStep[]
    model: PersonalInformationModel
    countries: Country[]
}>()

const selectedCountry = useModelSelect<Country>(props.countries, props.model.country_id)
const selectedCountryCode = computed(() => selectedCountry.value?.iso_code || 'nl')

const form = useForm({
    first_name: props.model.first_name,
    last_name: props.model.last_name,
    email: props.model.email,
    phone_number: props.model.phone_number,
    date_of_birth: props.model.date_of_birth,
    place_of_birth: props.model.place_of_birth,
    street: props.model.street,
    house_number: props.model.house_number,
    house_number_suffix: props.model.house_number_suffix,
    postal_code: props.model.postal_code,
    place_of_residence: props.model.place_of_residence,
    country_id: props.model.country_id,
})

watchEffect(() => {
    if (props.model.country_id != selectedCountry.value?.id) {
        form.street = ''
        form.postal_code = ''
        form.house_number = ''
        form.place_of_residence = ''
    }

    form.country_id = selectedCountry.value?.id || null
})

async function handleSubmit() {
    form.transform((data) => ({
        ...data,
        date_of_birth: data.date_of_birth ? formatISO(data.date_of_birth, { representation: 'date' }) : null,
    }))
    form.post('')
}

const { placeResult } = useGooglePlacesAutocomplete('#google-autocomplete', selectedCountryCode)

watch(placeResult, (place) => {
    form.postal_code = place.postalCode
    form.street = place.street
    form.place_of_residence = place.city
    form.house_number = place.streetNumber
})
</script>

<template>
    <ContractSignForm :steps="steps">
        <Form :form="form">
            <FormSection :title="$t('contract.sign.personal.section.personal')">
                <FormField class="col-span-12 sm:col-span-6" :required="true" prop="first_name" :label="$t('attributes.first_name')">
                    <Input id="first_name" v-model="form.first_name" type="text" />
                </FormField>

                <FormField class="col-span-12 sm:col-span-6" :required="true" prop="last_name" :label="$t('attributes.last_name')">
                    <Input id="last_name" v-model="form.last_name" type="text" />
                </FormField>

                <FormField class="col-span-12 sm:col-span-6" :required="true" prop="email" :label="$t('attributes.email')">
                    <Input id="email" v-model="form.email" type="text" />
                </FormField>

                <FormField class="col-span-12 sm:col-span-6" :required="true" prop="phone_number" :label="$t('attributes.phone_number')">
                    <Input id="phone_number" v-model="form.phone_number" type="text" />
                </FormField>

                <FormField class="col-span-12 sm:col-span-6" :required="true" prop="date_of_birth" :label="$t('attributes.date_of_birth')">
                    <VueDatePicker
                        id="date_of_birth"
                        v-model="form.date_of_birth"
                        :locale="'nl'"
                        :auto-position="false"
                        auto-apply
                        :enable-time-picker="false"
                        text-input
                        :text-input-options="{ format: 'dd-MM-yyyy' }"
                        format="dd-MM-yyyy"
                        class="mt-1 block w-full"
                    />
                </FormField>

                <FormField class="col-span-12 sm:col-span-6" :required="true" prop="place_of_birth" :label="$t('attributes.place_of_birth')">
                    <Input id="place_of_birth" v-model="form.place_of_birth" type="text" />
                </FormField>
            </FormSection>

            <FormSection :title="$t('contract.sign.personal.section.address')" class="pt-4">
                <FormField class="col-span-12 sm:col-span-6" :required="true" prop="country_id" :label="$t('attributes.country')">
                    <Dropdown
                        :items="countries"
                        propertyName="name"
                        :label="translatedSelectPlaceholder('attributes.country')"
                        :showLabel="false"
                        v-model="selectedCountry"
                        v-model:form="form.country_id"
                        :translateDb="true"
                    />
                </FormField>

                <FormField class="col-span-12 sm:col-span-6" :required="true" prop="street" :label="$t('attributes.street')">
                    <Input id="google-autocomplete" v-model="form.street" type="text" autocomplete="street" />
                </FormField>

                <FormField class="col-span-12 sm:col-span-6" :required="true" prop="postal_code" :label="$t('attributes.postal_code')">
                    <Input id="postal_code" v-model="form.postal_code" type="text" autocomplete="postal_code" />
                </FormField>

                <FormField class="col-span-12 sm:col-span-3" :required="true" prop="house_number" :label="$t('attributes.house_number')">
                    <Input id="house_number" v-model="form.house_number" type="text" autocomplete="house_number" />
                </FormField>

                <FormField class="col-span-12 sm:col-span-3" prop="house_number_suffix" :label="$t('attributes.house_number_suffix')">
                    <Input id="house_number_suffix" v-model="form.house_number_suffix" type="text" autocomplete="house_number_suffix" />
                </FormField>

                <FormField class="col-span-12 sm:col-span-6" :required="true" prop="place_of_residence" :label="$t('attributes.place_of_residence')">
                    <Input id="place_of_residence" v-model="form.place_of_residence" type="text" autocomplete="place_of_residence" />
                </FormField>
            </FormSection>

            <FormActions>
                <Button :disabled="form.processing" type="button" @click="handleSubmit">{{ $t('buttons.save') }}</Button>
            </FormActions>
        </Form>
    </ContractSignForm>
</template>
