<script setup lang="ts">
import ContentTabs from '@app/components/layout/ContentTabs.vue'
import { useNavigation } from '@app/composables/layout/useNavigation'
import { computed } from 'vue'
import { route } from 'ziggy-js'

const { currentRoute } = useNavigation()

const items = computed(() => [
    {
        name: 'settings.shifts.external-projects',
        href: route('settings.shifts.external-project.index'),
        active: currentRoute.value?.startsWith('settings.shifts.external-project'),
        permission: 'shifts.external-project.index',
    },
    {
        name: 'settings.shifts.templates',
        href: route('settings.shifts.template.index'),
        active: currentRoute.value?.startsWith('settings.shifts.template'),
        permission: 'shifts.template.index',
    },
])
</script>

<template>
    <div class="flex items-center justify-between gap-2">
        <ContentTabs :items="items" />

        <slot></slot>
    </div>
</template>
