<script setup lang="ts">
import SaveButton from '@app/components/ui/button/SaveButton.vue'
import CalendarInput from '@app/components/ui/form/CalendarInput.vue'
import Form from '@app/components/ui/form/Form.vue'
import FormActions from '@app/components/ui/form/FormActions.vue'
import FormField from '@app/components/ui/form/FormField.vue'
import FormSection from '@app/components/ui/form/FormSection.vue'
import MoneyInput from '@app/components/ui/form/MoneyInput.vue'
import type { ResultRewardLottery } from '@app/types/finance'
import { getDateFromDateTime } from '@app/utils/date'
import { useForm } from '@inertiajs/vue3'
import { computed } from 'vue'
import { route } from 'ziggy-js'

const { resultRewardLottery } = defineProps<{
    resultRewardLottery: ResultRewardLottery
}>()

const emit = defineEmits(['submit'])

const form = useForm({
    id: resultRewardLottery.id,
    client_project_id: resultRewardLottery.client_project_id,
    location_type_id: resultRewardLottery.location_type_id,
    location_valuation_id: resultRewardLottery.location_valuation_id ?? null,
    shift_member_amount: resultRewardLottery.shift_member_amount.amount,
    fonky_amount: resultRewardLottery.fonky_amount.amount,
    amount_start_date: '',
})

function submit() {
    form.post(route('settings.finance.result-reward-lottery.store'), {
        onSuccess: () => emit('submit'),
    })
}

const setSelectedDay = (day: string) => {
    form.amount_start_date = getDateFromDateTime(day, 'short')
}

const formTitle = computed(() => resultRewardLottery.client_project.short_name + ': ' + resultRewardLottery.location_type.slug)
</script>

<template>
    <Form :form="form" class="mt-8 w-4/5" @submit="submit">
        <FormSection :title="formTitle" class="mb-4">
            <FormField prop="shift_member_amount" class="col-span-12 sm:col-span-6" :label="$t('attributes.shift_member_amount')">
                <MoneyInput id="shift_member_amount" v-model="form.shift_member_amount" />
            </FormField>
            <FormField prop="fonky_amount" class="col-span-12 sm:col-span-6" :label="$t('attributes.fonky_amount')">
                <MoneyInput id="fonky_amount" v-model="form.fonky_amount" />
            </FormField>
        </FormSection>
        <FormField prop="amount_start_date" class="w-2/3" :label="$t('attributes.amount_start_date')">
            <CalendarInput @selectedDay:set="setSelectedDay" />
        </FormField>
        <FormActions>
            <SaveButton />
        </FormActions>
    </Form>
</template>
