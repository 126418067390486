<script setup lang="ts">
import { computed, provide } from 'vue'

const { days, disabledDates = [] } = defineProps<{ days: Date[]; disabledDates?: string[] }>()

provide(
    'calendar:days',
    computed(() => days)
)
provide('calendar:disabled', disabledDates)
</script>

<template>
    <div class="flex h-full flex-col">
        <slot />
    </div>
</template>
