import { applyFilter } from '@app/utils/filter'
import { format } from 'date-fns'
import { ref } from 'vue'
import { route } from 'ziggy-js'

type Options = {
    weekParamName: string
    reloadOnly: string[]
}

export function useWeekSwitcher(options: Options) {
    const selectedDay = ref(route().params[options.weekParamName] ? new Date(route().params[options.weekParamName]) : new Date())

    function switchWeek(date: Date) {
        applyFilter(
            { [options.weekParamName]: format(date, 'yyyy-MM-dd') },
            {
                only: options.reloadOnly,
                onSuccess() {
                    selectedDay.value = date
                },
            }
        )
    }

    return {
        selectedDay,
        switchWeek,
    }
}
