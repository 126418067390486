<script lang="ts" setup>
import CreateAvailabilityForm from '@app/components/recruitment/availabilities/CreateAvailabilityForm.vue'
import EventForm from '@app/components/recruitment/availabilities/EventForm.vue'
import Button from '@app/components/ui/button/Button.vue'
import Body from '@app/components/ui/calendar/body/Body.vue'
import TimeGrid from '@app/components/ui/calendar/body/TimeGrid.vue'
import TimeSlot from '@app/components/ui/calendar/body/TimeSlot.vue'
import Calendar from '@app/components/ui/calendar/Calendar.vue'
import Event from '@app/components/ui/calendar/Event.vue'
import Divider from '@app/components/ui/calendar/header/Divider.vue'
import Header from '@app/components/ui/calendar/header/Header.vue'
import WeekSwitcher from '@app/components/ui/calendar/header/WeekSwitcher.vue'
import Dropdown from '@app/components/ui/dropdown/Dropdown.vue'
import ModalLayout from '@app/components/ui/modal/ModalLayout.vue'
import FilterSection from '@app/components/ui/table/FilterSection.vue'
import { useUserCan } from '@app/composables/useUserCan'
import type { RecruitmentAppointmentHost, RecruitmentAvailability } from '@app/types/recruitment'
import type { User } from '@app/types/shared'
import { applyFilter } from '@app/utils/filter'
import { getAppointmentColors, getEventTitle, getSlotsTakenStyled, getThisWeeksAvailabilities } from '@app/utils/recruitment/recruitment-availability'
import { eachDayOfInterval, endOfWeek, isPast, isWeekend, startOfToday, startOfWeek } from 'date-fns'
import { computed, provide, ref, toRefs, watch } from 'vue'

const props = defineProps<{
    users: Array<User>
    availabilities: Array<RecruitmentAvailability>
    appointmentHosts: Array<RecruitmentAppointmentHost[]>
    selectedUser: User
    excludedDates: string[]
}>()

const { appointmentHosts, excludedDates } = toRefs(props)

provide('availability:excluded', excludedDates)
provide('availability:hosts', appointmentHosts)

const selectedUser = ref(props.selectedUser)

const { can } = useUserCan()

const selectedDay = ref(startOfToday())
const days = computed(() =>
    eachDayOfInterval({
        start: startOfWeek(selectedDay.value, { weekStartsOn: 1 }),
        end: endOfWeek(selectedDay.value, { weekStartsOn: 1 }),
    })
)
const weekdays = computed(() => days.value.filter((day) => !isWeekend(day)))

const availabilitiesThisWeek = computed(() => props.availabilities.filter((availability) => getThisWeeksAvailabilities(availability, selectedDay.value)))
const isPastWeek = computed(() => isPast(endOfWeek(selectedDay.value, { weekStartsOn: 1 })))

const openedAvailability = ref<RecruitmentAvailability | null>()
const openEventModal = (availability: RecruitmentAvailability) => {
    openedAvailability.value = availability
}

const showCreateAvaibilityForm = ref(false)

const closeEventModal = () => (openedAvailability.value = null)

watch(selectedUser, () => {
    applyFilter({ user_id: selectedUser.value?.id })
})
</script>

<template>
    <Calendar :days="weekdays" :disabled-dates="excludedDates">
        <Header :date="selectedDay">
            <WeekSwitcher v-model="selectedDay" />
            <Divider />
            <Button :disabled="isPastWeek" color="primary" @click="showCreateAvaibilityForm = true">
                {{ $t('calendar.availability.add') }}
            </Button>
        </Header>

        <FilterSection :showPerPageOptions="false" class="-ml-4">
            <Dropdown v-if="can('recruitment.availability.manage')" :items="users" v-model="selectedUser" propertyName="full_name" :translateDb="false" />
        </FilterSection>

        <Body>
            <TimeGrid :start="9" :end="22">
                <TimeSlot v-for="availability in availabilitiesThisWeek" :from="availability.from" :to="availability.to">
                    <Event
                        :title="getEventTitle(availability)"
                        :from="availability.from"
                        :to="availability.to"
                        :content="getSlotsTakenStyled(availability)"
                        :colors="getAppointmentColors(availability)"
                        @click="openEventModal(availability)"
                    />
                </TimeSlot>
            </TimeGrid>
        </Body>
    </Calendar>

    <ModalLayout :show="!!openedAvailability" @close="closeEventModal">
        <EventForm v-if="openedAvailability" :availability="openedAvailability" @close="closeEventModal" :key="openedAvailability.id" />
    </ModalLayout>

    <ModalLayout :show="showCreateAvaibilityForm" @close="showCreateAvaibilityForm = false">
        <CreateAvailabilityForm :user="selectedUser" :days="weekdays" :date="selectedDay" @close="showCreateAvaibilityForm = false" />
    </ModalLayout>
</template>
