<script setup lang="ts">
import ContractFooter from '@app/components/contracts/sign/ContractFooter.vue'
import ContractHeader from '@app/components/contracts/sign/ContractHeader.vue'
import ContractSignForm from '@app/components/contracts/sign/ContractSignForm.vue'
import Button from '@app/components/ui/button/Button.vue'
import Form from '@app/components/ui/form/Form.vue'
import FormActions from '@app/components/ui/form/FormActions.vue'
import FormField from '@app/components/ui/form/FormField.vue'
import SignaturePad from '@app/components/ui/form/SignaturePad.vue'
import type { FormStep } from '@app/components/ui/multiStepForm/types'
import { useFormWizard } from '@app/composables/form/useFormWizard'
import { useSignatureField } from '@app/composables/form/useSignatureField'
import Guest from '@app/layouts/Guest.vue'
import { useForm } from '@inertiajs/vue3'

defineOptions({
    layout: Guest,
})

type SignatureModel = {
    user_signature: string | null
}

const props = defineProps<{
    steps: FormStep[]
    text: string
    user_signature: string | null
    employee_first_name: string
    employee_full_name: string
}>()

const { navigateToPreviousStep } = useFormWizard(props.steps)

const form = useForm<SignatureModel>({
    user_signature: null,
})

const { signatureModel } = useSignatureField(form, 'user_signature', props.user_signature)

async function handleSubmit() {
    form.post('', {
        preserveScroll: (page) => Object.keys(page.props.errors).length > 0,
        preserveState: (page) => Object.keys(page.props.errors).length > 0,
    })
}
</script>

<template>
    <ContractSignForm :steps="steps">
        <Form :form="form">
            <ContractHeader :name="employee_first_name" />

            <div v-html="text"></div>

            <ContractFooter :employee-name="employee_full_name">
                <FormField prop="signature" class="mt-3 max-w-xs">
                    <SignaturePad v-model="signatureModel" :label="$t('form.labels.signature')" />
                </FormField>
            </ContractFooter>

            <FormActions>
                <Button color="white" type="button" @click="navigateToPreviousStep">{{ $t('buttons.back') }}</Button>
                <Button :disabled="form.processing" type="button" @click="handleSubmit">{{ $t('buttons.save') }}</Button>
            </FormActions>
        </Form>
    </ContractSignForm>
</template>
