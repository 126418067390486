<script setup lang="ts">
import RoundButton from '@app/components/ui/button/RoundButton.vue'
import { useActiveButton } from '@app/composables/useDetailCard'
import { useUserCan } from '@app/composables/useUserCan'
import type { Contract } from '@app/types/contracts'
import { PencilSquareIcon } from '@heroicons/vue/24/solid'
import { computed } from 'vue'
import ContractChangeForm from '../forms/ContractChangeForm.vue'
import ContractEditForm from '../forms/ContractEditForm.vue'

const props = defineProps<{ contract: Contract }>()

const { can } = useUserCan()
const { toggle } = useActiveButton()

const show = computed(() => can('contract.store') && ['new', 'active'].includes(props.contract.status.slug) && !props.contract.has_next_contract)

function handleButtonClick() {
    if (props.contract.status.slug === 'new') {
        toggle(ContractEditForm)
    } else {
        toggle(ContractChangeForm)
    }
}
</script>

<template>
    <RoundButton v-if="show" :label="$t('contract.actions.edit')" @click="handleButtonClick">
        <PencilSquareIcon class="h-4 w-4" aria-hidden="true" />
    </RoundButton>
</template>
