<script setup lang="ts">
import { isExcluded } from '@app/utils/openingHours'
import { eachHourOfInterval, format, setHours } from 'date-fns'
import { computed, inject, provide, type ComputedRef } from 'vue'

const { start = 0, end = 24 } = defineProps<{ start: number; end: number }>()

provide('calendar:time:start', start)
provide('calendar:time:end', end)

const days = inject('calendar:days') as ComputedRef<Date[]>
const excludedDates = inject('calendar:disabled') as string[]

const columns = computed(() => days.value.length)

const hours = computed(() =>
    eachHourOfInterval({
        start: setHours(new Date(), start),
        end: setHours(new Date(), end),
    })
)

const timeRows = computed(() => end - start + 1)

const gridTemplateRows = computed(() => `repeat(${timeRows.value * 4}, minmax(1rem, 1fr))`)
</script>

<template>
    <div class="sticky left-0 z-10 w-14 flex-none bg-white ring-1 ring-gray-100" />
    <div class="grid flex-auto grid-cols-1 grid-rows-1">
        <!-- Horizontal lines -->
        <div class="col-start-1 col-end-2 row-start-1 grid divide-y divide-gray-100" :style="{ 'grid-template-rows': `${gridTemplateRows} auto` }">
            <div class="row-end-1 h-7" />
            <div v-for="hour in hours" class="row-span-4">
                <div class="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    {{ format(hour, 'HH:mm') }}
                </div>
            </div>
        </div>

        <!-- Vertical lines -->
        <div
            class="col-start-1 col-end-2 row-start-1 grid grid-rows-1 divide-x divide-gray-100"
            :style="{ 'grid-template-columns': `repeat(${columns}, minmax(0, 1fr))` }"
        >
            <div v-for="day in days" :class="{ 'bg-gray-100': isExcluded(day, excludedDates) }"></div>
        </div>

        <!-- Events -->
        <div
            class="col-start-1 col-end-2 row-start-1 grid w-full"
            :style="{
                'grid-template-columns': `repeat(${columns}, minmax(0, 1fr))`,
                'grid-template-rows': `1.75rem ${gridTemplateRows} auto`,
            }"
        >
            <slot />
        </div>
    </div>
</template>
