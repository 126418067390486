<script setup lang="ts">
import RoundButton from '@app/components/ui/button/RoundButton.vue'
import { useUserCan } from '@app/composables/useUserCan'
import type { Shift } from '@app/types/shifts'
import { ArrowPathIcon } from '@heroicons/vue/24/solid'
import { useForm } from '@inertiajs/vue3'
import { computed } from 'vue'
import { route } from 'ziggy-js'

const { shift } = defineProps<{ shift: Shift }>()

const { can } = useUserCan()
const show = computed(() => can('shifts.shift.refresh-briggs-walker') && shift.application.slug === 'briggs-and-walker')

const form = useForm({
    external_id: shift?.external_id,
    date: shift.start,
})

const refreshBriggsWalkerShift = () => {
    form.post(route('shifts.shift.refresh'), {
        only: ['selectedShift'],
    })
}
</script>

<template>
    <RoundButton v-if="show" :label="$t('actions.refresh')" @click="refreshBriggsWalkerShift()">
        <ArrowPathIcon class="size-5" aria-hidden="true" />
    </RoundButton>
</template>
