<script setup lang="ts">
import Button from '@app/components/ui/button/Button.vue'
import DataTable from '@app/components/ui/table/DataTable.vue'
import { useUserCan } from '@app/composables/useUserCan'
import Settings from '@app/layouts/Settings.vue'
import type { PageProps } from '@app/types/inertia'
import type { Client, ResourceCollection } from '@app/types/shared'
import { Link, router, usePage } from '@inertiajs/vue3'
import { route } from 'ziggy-js'

defineProps<{
    clients: ResourceCollection<Client>
}>()

const user = usePage<PageProps>().props.user
const { can } = useUserCan()

function deleteClient(id: number) {
    router.delete(route('settings.client.delete', { id: id }))
}

function createClient() {
    router.get(route('settings.client.create'))
}

var columns = [
    {
        type: 'string',
        header: 'ID',
        properties: { name: 'id' },
        show: user.is_super_admin,
    },
    {
        type: 'string',
        header: 'attributes.name',
        properties: { name: 'name' },
    },
    {
        type: 'string',
        header: 'attributes.email',
        properties: { name: 'email' },
    },
    {
        type: 'string',
        header: 'attributes.phone_number',
        properties: { name: 'phone_number' },
    },
    {
        type: 'string',
        header: 'attributes.country',
        properties: { name: 'name', relation: 'country', translate: true },
    },
    {
        type: 'string',
        header: 'attributes.color',
        properties: { name: 'name', relation: 'color', translate: true },
    },
    {
        name: 'actions',
        header: 'actions.header',
    },
]
</script>

<template>
    <Settings group="general">
        <div class="mb-4 md:flex md:items-center md:justify-between">
            <div class="flex place-items-center">
                <Button v-if="can('client.store')" @click="createClient"> {{ $t('actions.create') }} </Button>
            </div>
        </div>
        <div class="mb-6 md:flex md:items-center md:justify-between">
            <DataTable class="flex w-full flex-col rounded-lg border border-gray-100 transition-all duration-500" :items="clients" :columns="columns">
                <template v-slot:column.actions="{ item }">
                    <Link class="mr-2 text-primary-600" :href="route('settings.client.edit', { id: item.id })">
                        {{ $t('actions.update') }}
                    </Link>
                    <button type="button" class="text-primary-600" @click="deleteClient(item.id)">
                        {{ $t('actions.delete') }}
                    </button>
                </template>
            </DataTable>
        </div>
    </Settings>
</template>
