<script setup lang="ts">
import ContractDetailCard from '@app/components/contracts/ContractDetailCard.vue'
import ContractStatus from '@app/components/contracts/ContractStatus.vue'
import ContractStatusLabel from '@app/components/contracts/ContractStatusLabel.vue'
import ContractCreateForm from '@app/components/contracts/forms/ContractCreateForm.vue'
import ContentTabs from '@app/components/layout/ContentTabs.vue'
import Button from '@app/components/ui/button/Button.vue'
import DataTable from '@app/components/ui/table/DataTable.vue'
import FilterSection from '@app/components/ui/table/FilterSection.vue'
import DropdownMultiFilter from '@app/components/ui/table/filters/DropdownMultiFilter.vue'
import ResetFilterButton from '@app/components/ui/table/filters/ResetFilterButton.vue'
import { useStoredFilters } from '@app/composables/table/useStoredFilters'
import { useDetailCard } from '@app/composables/useDetailCard'
import { useChannel } from '@app/composables/useEcho'
import { useLocale } from '@app/composables/useLocale'
import { useUserCan } from '@app/composables/useUserCan'
import type { Contract, ContractStatus as ContractStatusType, GeneratedFiles } from '@app/types/contracts'
import type { Branch, ResourceCollection } from '@app/types/shared'
import { formatContractDuration } from '@app/utils/contracts/contract'
import { getZonedDate } from '@app/utils/date'
import { applyFilter } from '@app/utils/filter'
import { reloadPartial } from '@app/utils/inertia'
import { PlusIcon } from '@heroicons/vue/20/solid'
import { computed, onMounted, provide, toRefs } from 'vue'
import { route } from 'ziggy-js'

const { getStoredFilters } = useStoredFilters()
const { getLocale } = useLocale()
const { can } = useUserCan()

const props = defineProps<{
    contracts: ResourceCollection<Contract & GeneratedFiles>
    contractStatuses: ContractStatusType[]
    statusFilters: ContractStatusType[]
    branches: Branch[]
    cancellationReasons: object[]
    selectedContract: (Contract & GeneratedFiles) | undefined
    scope: 'expiring' | 'new' | 'active' | null
    create: boolean
}>()

const { selectedContract, create } = toRefs(props)

provide('contract:cancellationReasons', props.cancellationReasons)

const params = route().params

const { toggleCard } = useDetailCard(selectedContract, ['selectedContract', 'create'])

const columns = computed(() => [
    { name: 'name', header: 'attributes.name' },
    { name: 'phase', header: 'attributes.phase' },
    { name: 'status', header: 'attributes.status' },
    {
        name: 'start_date',
        header: 'attributes.start_date',
        sort: 'start_date',
    },
    {
        name: 'end_date',
        header: 'attributes.end_date',
        sort: 'end_date',
    },
    { name: 'branch', header: 'attributes.branch_id' },
    { name: 'duration', header: 'attributes.duration' },
])

const tabs = computed(() => [
    {
        name: 'contract.tabs.new',
        href: route('contract.index', { ...getStoredFilters('contract.index.new'), scope: 'new' }),
        active: props.scope === 'new',
        permission: 'contract.index',
    },
    {
        name: 'contract.tabs.active',
        href: route('contract.index', { ...getStoredFilters('contract.index.active'), scope: 'active' }),
        active: props.scope === 'active',
        permission: 'contract.index',
    },
    {
        name: 'contract.tabs.expiring',
        href: route('contract.index', { ...getStoredFilters('contract.index.expiring'), scope: 'expiring' }),
        active: props.scope === 'expiring',
        permission: 'contract.index',
    },
    {
        name: 'contract.tabs.all',
        href: route('contract.index', getStoredFilters('contract.index')),
        active: props.scope === null,
        permission: 'contract.index',
    },
])

const { subscribe } = useChannel('contracts')

onMounted(() => {
    subscribe('.contract.linked', (event: { contract_id: number; user_id: number }) => {
        if (selectedContract.value?.id === event.contract_id) {
            reloadPartial(['contracts', 'selectedContract'])
        }
    })

    subscribe('.email.sent', (payload: { mailable_id: number; mailable_type: string }) => {
        if (selectedContract.value?.id === payload.mailable_id) {
            reloadPartial(['selectedContract'])
        }
    })
})

function showNextStatus(contract: Contract) {
    return contract.latest_contract_status && contract.latest_contract_status.id !== contract.status.id
}

function showCreateForm() {
    applyFilter({
        selected: null,
        create: true,
    })
}

function closeForm() {
    applyFilter({
        selected: null,
        create: null,
    })
}
</script>

<template>
    <div class="flex items-center justify-between">
        <ContentTabs :items="tabs" />

        <Button v-if="can('contract.store')" @click="showCreateForm">
            <PlusIcon class="h-5 w-5 text-white" />
            {{ $t('contract.create.title') }}
        </Button>
    </div>
    <div class="mt-4 flex items-start gap-4 scrollbar-hide">
        <DataTable
            class="flex w-full flex-col rounded-lg border border-gray-100 transition-all duration-500"
            :class="[!selectedContract && !create ? 'inline-block' : 'hidden lg:inline-block']"
            :items="contracts"
            :columns="columns"
            only="contracts"
            :clickable="true"
            :activeId="selectedContract?.id"
            @row:clicked="(contract) => toggleCard(contract.id, ['create'])"
        >
            <template #filter>
                <FilterSection :enable-search="true" :text-search-param="params.name">
                    <DropdownMultiFilter
                        v-if="scope !== 'expiring'"
                        :items="statusFilters"
                        propertyName="name"
                        :label="$t('attributes.status')"
                        only="contracts"
                        by="slug"
                        slug="status"
                    />

                    <DropdownMultiFilter :items="branches" propertyName="name" :label="$t('attributes.branch_id')" only="contracts" slug="branch" by="slug" />

                    <ResetFilterButton :href="route('contract.index', { scope })" :label="$t('buttons.clear')" />
                </FilterSection>
            </template>

            <template v-slot:column.name="{ item }">
                {{ item.full_name }}
            </template>

            <template v-slot:column.phase="{ item }">
                {{ item.phase }}
            </template>

            <template v-slot:column.status="{ item }">
                <template v-if="item.latest_contract_status && showNextStatus(item)">
                    <ContractStatusLabel :status="item.status" :terminated-at="item.terminated_at" />
                    ->
                    <ContractStatusLabel :status="item.latest_contract_status" />
                </template>

                <ContractStatus v-else :contract="item" />
            </template>

            <template v-slot:column.start_date="{ item }">
                {{ getZonedDate(item.start_date) }}
            </template>

            <template v-slot:column.end_date="{ item }">
                {{ getZonedDate(item.end_date) }}
            </template>

            <template v-slot:column.branch="{ item }">
                {{ item.branch?.name }}
            </template>

            <template v-slot:column.duration="{ item }">
                {{ $t(formatContractDuration(item.duration_in_months, getLocale())) }}
            </template>
        </DataTable>

        <Transition name="slide-fade" mode="out-in">
            <ContractDetailCard v-if="selectedContract" :contract="selectedContract" />
            <ContractCreateForm v-else-if="create" class="md:min-w-[29rem] lg:w-2/3" @close="closeForm" />
        </Transition>
    </div>
</template>
