import type { RecruitmentAvailability } from '@app/types/recruitment'
import { getAppointmentDateFromDateTime, getTimeFromDateTime } from '@app/utils/date'
import { endOfWeek, isPast, isWeekend, startOfWeek } from 'date-fns'

export function getAppointmentColors(availability: RecruitmentAvailability) {
    const to = new Date(availability.to)
    const isCompleted = isPast(to)

    if (availability.funnel_block_slug === 'introduction') {
        return availability.slots_taken > 0 && !isCompleted ? 'primary' : 'primaryLight'
    }

    if (availability.funnel_block_slug === 'workshop') {
        return availability.slots_taken > 0 && !isCompleted ? 'secondary' : 'secondaryLight'
    }
}

export function getEventTitle(availability: RecruitmentAvailability) {
    return availability.branch_name + ' - ' + availability.funnel_block_name
}

export function getThisWeeksAvailabilities(availability: RecruitmentAvailability, date: Date) {
    let from = new Date(availability.from)
    let to = new Date(availability.to)

    if (isWeekend(from)) {
        return false
    }

    return from >= startOfWeek(date, { weekStartsOn: 1 }) && to <= endOfWeek(date, { weekStartsOn: 1 })
}

export function getSlotsTakenStyled(availability: RecruitmentAvailability) {
    return availability.slots_taken + '/' + availability.slots
}

export function getFromToWithSlotsTaken(availability: RecruitmentAvailability) {
    return (
        getAppointmentDateFromDateTime(availability.from, 'short') +
        ' ' +
        getTimeFromDateTime(availability.from) +
        ' - ' +
        getTimeFromDateTime(availability.to) +
        ' (' +
        getSlotsTakenStyled(availability) +
        ')'
    )
}
