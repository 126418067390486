<script setup lang="ts">
import Button from '@app/components/ui/button/Button.vue'
import { useUserCan } from '@app/composables/useUserCan'
import { router } from '@inertiajs/vue3'
import { computed } from 'vue'
import { route } from 'ziggy-js'

const { can } = useUserCan()

const { only } = defineProps<{
    only?: string[]
}>()

const showRefreshBriggsWalker = computed(() => can('shifts.shift.refresh-briggs-walker'))

const refreshBriggsWalker = () => {
    router.post(route('shifts.shift.refresh-all'), {
        only: only,
    })
}
</script>

<template>
    <Button @click="refreshBriggsWalker" v-if="showRefreshBriggsWalker">
        {{ $t('shifts.refresh_briggs_walker') }}
    </Button>
</template>
