<script setup lang="ts">
import Button from '@app/components/ui/button/Button.vue'
import Dropdown from '@app/components/ui/dropdown/Dropdown.vue'
import Form from '@app/components/ui/form/Form.vue'
import FormActions from '@app/components/ui/form/FormActions.vue'
import FormField from '@app/components/ui/form/FormField.vue'
import FormSection from '@app/components/ui/form/FormSection.vue'
import Input from '@app/components/ui/form/Input.vue'
import { useModelSelect } from '@app/composables/useModelSelect'
import type { Color, Country } from '@app/types/shared'
import { translatedSelectPlaceholder } from '@app/utils/form'
import { router, useForm } from '@inertiajs/vue3'
import { route } from 'ziggy-js'

const props = defineProps<{
    countries: Country[]
    colors: Color[]
    client?: any
}>()

const selectedCountry = useModelSelect<Country>(props.countries, props.client?.country_id)
const selectedColor = useModelSelect<Color>(props.colors, props.client?.color_id)

const form = useForm({
    name: props.client?.name || null,
    slug: props.client?.slug || null,
    email: props.client?.email || null,
    phone_number: props.client?.phone_number || null,
    street: props.client?.street || null,
    house_number: props.client?.house_number || null,
    house_number_suffix: props.client?.house_number_suffix || null,
    place_of_residence: props.client?.place_of_residence || null,
    postal_code: props.client?.postal_code || null,
    country_id: props.client?.country_id || null,
    color_id: props.client?.color_id || null,
    post_office_box: props.client?.post_office_box || null,
})

function submit() {
    if (props.client) {
        form.put(route('settings.client.update', props.client.id))
    } else {
        form.post(route('settings.client.store'))
    }
}
</script>

<template>
    <Form :form="form" @submit="submit" class="max-w-4xl">
        <FormSection :title="$t('Personal')">
            <FormField prop="name" :label="$t('attributes.name')" class="col-span-12 sm:col-span-6">
                <Input id="name" v-model="form.name" />
            </FormField>

            <FormField prop="slug" label="Slug" class="col-span-12 sm:col-span-6">
                <Input id="slug" v-model="form.slug" />
            </FormField>

            <FormField prop="email" :label="$t('attributes.email')" class="col-span-12 sm:col-span-6">
                <Input id="email" v-model="form.email" />
            </FormField>

            <FormField prop="phone_number" :label="$t('attributes.phone_number')" class="col-span-6">
                <Input id="phone_number" v-model="form.phone_number" />
            </FormField>
        </FormSection>

        <FormSection :title="$t('form.section.address')">
            <FormField prop="street" :label="$t('attributes.street')" class="col-span-12 sm:col-span-6">
                <Input id="street" v-model="form.street" />
            </FormField>

            <FormField prop="house_number" :label="$t('attributes.house_number')" class="col-span-12 sm:col-span-6">
                <Input id="house_number" type="number" v-model="form.house_number" />
            </FormField>

            <FormField prop="house_number_suffix" :label="$t('attributes.house_number_suffix')" class="col-span-12 sm:col-span-6">
                <Input id="house_number_suffix" v-model="form.house_number_suffix" />
            </FormField>

            <FormField prop="place_of_residence" :label="$t('attributes.place_of_residence')" class="col-span-12 sm:col-span-6">
                <Input id="place_of_residence" v-model="form.place_of_residence" />
            </FormField>

            <FormField prop="postal_code" :label="$t('attributes.postal_code')" class="col-span-12 sm:col-span-6">
                <Input id="postal_code" v-model="form.postal_code" />
            </FormField>

            <FormField prop="country" :label="$t('attributes.country')" class="col-span-12 sm:col-span-6">
                <Dropdown
                    :items="countries"
                    propertyName="name"
                    :label="translatedSelectPlaceholder('attributes.country')"
                    :showLabel="false"
                    :translateDb="true"
                    v-model="selectedCountry"
                    v-model:form="form.country_id"
                />
            </FormField>

            <FormField prop="post_office_box" :label="$t('attributes.post_office_box')" class="col-span-12">
                <Input id="post_office_box" v-model="form.post_office_box" />
            </FormField>

            <FormField prop="color" :label="$t('attributes.color')" class="col-span-12 sm:col-span-6">
                <Dropdown
                    :items="colors"
                    propertyName="name"
                    :label="translatedSelectPlaceholder('attributes.color')"
                    :showLabel="false"
                    :translateDb="true"
                    v-model="selectedColor"
                    v-model:form="form.color_id"
                />
            </FormField>
        </FormSection>

        <FormActions>
            <Button color="black" type="button" @click="router.get(route('settings.client.index'))">{{ $t('buttons.back') }}</Button>
            <Button :disabled="form.processing">{{ $t('buttons.save') }}</Button>
        </FormActions>
    </Form>
</template>
