<script setup lang="ts">
import type { Contract } from '@app/types/contracts'
import { getZonedDate } from '@app/utils/date'
import { Link } from '@inertiajs/vue3'
import { trans } from 'laravel-vue-i18n'
import type { PropType } from 'vue'
import { route } from 'ziggy-js'
import ContractStatusLabel from '../../ContractStatusLabel.vue'

defineProps({
    contracts: {
        type: Array as PropType<Contract[]>,
        required: true,
    },
    selectedContractId: Number,
})

function getContractLink(contractId: number) {
    return route('contract.index', {
        ...route().params,
        selected: contractId,
    })
}

function getFormattedEndDate(contract: Contract) {
    if (contract.terminated_at) {
        return getZonedDate(contract.terminated_at)
    }

    if (contract.end_date) {
        return getZonedDate(contract.end_date)
    }

    return trans('contract.permanent')
}
</script>

<template>
    <div class="mt-4 space-y-2">
        <div
            v-for="contract in contracts"
            class="flex h-fit items-center justify-between gap-2 rounded-sm px-2 py-1"
            :class="[contract.id === selectedContractId ? 'bg-secondary-100' : 'bg-white hover:bg-zinc-50']"
        >
            <div class="cursor-default text-sm font-normal leading-6 text-zinc-600 transition-all duration-150">
                <Link :href="getContractLink(contract.id)"
                    >{{ getZonedDate(contract.start_date) }} - {{ getFormattedEndDate(contract) }} ({{ contract.phase }}
                    {{ $t('attributes.phase').toLowerCase() }})</Link
                >
            </div>

            <ContractStatusLabel :status="contract.status" />
        </div>
    </div>
</template>
