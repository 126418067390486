<script setup lang="ts">
import CancelButton from '@app/components/ui/button/CancelButton.vue'
import SaveButton from '@app/components/ui/button/SaveButton.vue'
import Card from '@app/components/ui/card/Card.vue'
import FormActions from '@app/components/ui/form/FormActions.vue'
import FromToDate from '@app/components/ui/table/column/FromToDate.vue'
import type { ShiftAvailability } from '@app/types/shifts'
import { useForm } from '@inertiajs/vue3'
import { computed, watch } from 'vue'
import { route } from 'ziggy-js'

const { selected } = defineProps<{
    selected: ShiftAvailability[]
}>()

const emit = defineEmits(['close'])

const selectedIds = computed(() => selected.map((item) => item.id))

const form = useForm({
    availabilities: selectedIds.value,
})

watch(selectedIds, () => {
    form.availabilities = selectedIds.value
})

function createShifts() {
    form.post(route('shifts.shift.create-individual'), {
        only: ['users'],
        onSuccess() {
            emit('close')
        },
    })
}
</script>

<template>
    <Card dismissable class="w-full md:w-1/3" @close="emit('close')">
        <div class="mt-2 min-h-96 w-full">
            <div class="text-left text-xl font-semibold leading-7 text-zinc-900">Create shift(s)</div>

            <div v-if="form.availabilities.length === 0" class="mt-4 min-h-12 text-gray-500">
                Start by selecting availabilities you want to create shifts for.
            </div>

            <div class="mt-4 space-y-4">
                <div v-for="availability in selected" class="rounded-lg border border-gray-200 px-4 py-2">
                    <p>{{ availability.user_full_name }}</p>
                    <p class="mt-1 text-xs text-gray-500"><FromToDate :from="availability.from" :to="availability.to" /></p>
                    <p class="mt-1 text-xs text-gray-500">{{ availability.client_project_name }} - {{ availability.branch_name }}</p>
                </div>
            </div>

            <FormActions>
                <CancelButton @click="$emit('close')" />
                <SaveButton :disabled="form.availabilities.length === 0" @click="createShifts" />
            </FormActions>
        </div>
    </Card>
</template>
