<script setup lang="ts">
import Input from '@app/components/ui/form/Input.vue'
import { useLocale } from '@app/composables/useLocale'
import VueDatePicker from '@vuepic/vue-datepicker'
import { addDays, addMonths, formatDuration, intervalToDuration, subDays } from 'date-fns'
import { ref, useTemplateRef, watch, watchEffect } from 'vue'

const model = defineModel<Date | null>()

const { getLocale } = useLocale()

const dateFormat = 'dd-MM-yyyy'

const { disabled = false, startDate = new Date() } = defineProps<{
    startDate?: Date
    disabled?: boolean
}>()

const months = ref<number>()
const days = ref<number>()

function initializeValues() {
    if (model.value && startDate) {
        const duration = intervalToDuration({ start: startDate, end: addDays(model.value, 1) })

        const durationInMonths = (duration.years || 0) * 12 + (duration.months || 0)

        if (durationInMonths) {
            months.value = durationInMonths
        }

        if (duration.days) {
            days.value = duration.days
        }
    } else {
        resetValues()
    }
}

watch(model, initializeValues)

const picker = useTemplateRef('datepicker')

function changeEndDate(value: number) {
    months.value = value
    days.value = undefined
}

function clearEndDate() {
    resetValues()

    picker.value?.clearValue()
}

watchEffect(() => {
    let date

    if (months.value) {
        date = addMonths(startDate, +months.value)
    }

    if (days.value) {
        date = addDays(date || startDate, +days.value)
    }

    if (date) {
        date = subDays(date, 1)
        picker.value?.updateInternalModelValue(date)
    }
})

const durationPresets = [3, 6, 12]

function resetValues() {
    months.value = undefined
    days.value = undefined

    if (!model.value) {
        picker.value?.updateInternalModelValue(null)
    }
}
</script>

<template>
    <VueDatePicker
        ref="datepicker"
        v-model="model"
        :enable-time-picker="false"
        :locale="'nl'"
        text-input
        :text-input-options="{ format: dateFormat }"
        :format="dateFormat"
        :placeholder="$t('contract.permanent')"
        :disabled
        @closed="resetValues"
        @open="initializeValues"
        :select-text="$t('buttons.select')"
    >
        <template #left-sidebar>
            <div class="w-56 px-4 pt-2">
                <label class="block text-sm font-medium leading-5">{{ $t('attributes.duration') }}</label>

                <div class="mt-2 flex gap-2">
                    <div>
                        <label class="mb-1 block text-xs font-medium">{{ $t('calendar.months') }}</label>
                        <Input v-model="months" :placeholder="$t('calendar.months')" />
                    </div>

                    <div>
                        <label class="mb-1 block text-xs font-medium">{{ $t('calendar.days') }}</label>
                        <Input v-model="days" :placeholder="$t('calendar.days')" />
                    </div>
                </div>

                <div class="mt-2">
                    <button v-for="numberOfMonths in durationPresets" type="button" class="dp__btn dp--preset-range" @click="changeEndDate(numberOfMonths)">
                        {{ formatDuration({ months: numberOfMonths }, { locale: getLocale() }) }}
                    </button>

                    <button type="button" class="dp__btn dp--preset-range" @click="clearEndDate">{{ $t('contract.permanent') }}</button>
                </div>
            </div>
        </template>
    </VueDatePicker>
</template>
