<script setup lang="ts">
import Form from '@app/components/settings/finance/resultRewardDonations/Form.vue'
import Card from '@app/components/ui/card/Card.vue'
import DataTable from '@app/components/ui/table/DataTable.vue'
import DropdownMultiFilter from '@app/components/ui/table/filters/DropdownMultiFilter.vue'
import ResetFilterButton from '@app/components/ui/table/filters/ResetFilterButton.vue'
import FilterSection from '@app/components/ui/table/FilterSection.vue'
import { useDetailCard } from '@app/composables/useDetailCard'
import Settings from '@app/layouts/Settings.vue'
import { type ResultRewardDonation } from '@app/types/finance'
import type { ClientProject, ResourceCollection } from '@app/types/shared'
import { applyFilter } from '@app/utils/filter'
import { route } from 'ziggy-js'

const { selectedResultRewardDonation } = defineProps<{
    resultRewardDonations: ResourceCollection<ResultRewardDonation>
    clientProjects: ResourceCollection<ClientProject>
    selectedResultRewardDonation: ResultRewardDonation | null
}>()

const { toggleCard } = useDetailCard(selectedResultRewardDonation, 'selectedResultRewardDonation')

const columns = [
    {
        type: 'string',
        header: 'attributes.client_project',
        properties: { name: 'name', relation: 'client_project' },
    },
    {
        type: 'string',
        header: 'attributes.tier',
        properties: { name: 'tier' },
    },
    {
        type: 'amount',
        header: 'attributes.from',
        properties: { name: 'from' },
        sort: 'from',
    },
    {
        type: 'amount',
        header: 'attributes.to',
        properties: { name: 'to' },
        sort: 'to',
    },
    {
        type: 'amount',
        header: 'attributes.shift_member_amount',
        properties: { name: 'shift_member_amount' },
        sort: 'shift_member_amount',
    },
    {
        type: 'amount',
        header: 'attributes.fonky_amount',
        properties: { name: 'fonky_amount' },
        sort: 'fonky_amount',
    },
    {
        type: 'date',
        header: 'attributes.amount_start_date',
        properties: { name: 'amount_start_date' },
        sort: 'amount_start_date',
    },
]

function closeForm() {
    applyFilter({
        selected: null,
    })
}
</script>

<template>
    <Settings group="finance">
        <div class="md:items-top mb-6 md:flex md:justify-between">
            <DataTable
                class="flex w-full flex-col rounded-lg border border-gray-100 transition-all duration-500"
                :items="resultRewardDonations"
                :columns="columns"
                only="resultRewardDonations"
                :clickable="true"
                :activeId="selectedResultRewardDonation?.id"
                @row:clicked="(item) => toggleCard(item.id)"
            >
                <template #filter>
                    <FilterSection>
                        <DropdownMultiFilter
                            :items="clientProjects"
                            :label="$t('attributes.client_project')"
                            propertyName="name"
                            slug="client_project"
                            by="slug"
                            :translateDb="false"
                        />
                        <ResetFilterButton class="mt-2" :href="route('settings.finance.result-reward-donation.index')" :label="$t('buttons.clear')" />
                    </FilterSection>
                </template>
            </DataTable>
            <Transition name="slide-fade" mode="out-in">
                <Card v-if="selectedResultRewardDonation" class="md:min-w-[29rem] lg:w-2/3" @close="closeForm" :dismissable="true">
                    <Form :resultRewardDonation="selectedResultRewardDonation" :key="selectedResultRewardDonation?.id" @submit="closeForm" />
                </Card>
            </Transition>
        </div>
    </Settings>
</template>
