<script setup lang="ts">
import { differenceInMinutes, format } from 'date-fns'
import { computed, inject } from 'vue'

const { from, to } = defineProps<{ from: string; to: string }>()

// Safelisted col-start- in tailwind.config.js.
// https://tailwindcss.com/docs/content-configuration#using-regular-expressions
const colStart = computed(() => {
    let dayNumber = format(new Date(from), 'i')

    return `col-start-${dayNumber}`
})

const startingHours = inject('calendar:time:start') as number

const rowNumber = computed(() => {
    const initialRowOffset = 2
    const date = new Date(from)

    const rowsFromHours = ((date.getHours() - startingHours) % 12) * 4
    const rowsFromMinutes = Math.floor(date.getMinutes() / 15)

    return initialRowOffset + rowsFromHours + rowsFromMinutes
})

const rowSpan = computed(() => {
    const dateFrom = new Date(from)
    const dateTo = new Date(to)

    const durationInMinutes = Math.abs(differenceInMinutes(dateFrom, dateTo))

    return Math.floor(durationInMinutes / 15)
})

const gridRow = computed(() => `${rowNumber.value} / span ${rowSpan.value}`)
</script>

<template>
    <div :class="['relative', 'flex', colStart]" :style="{ 'grid-row': gridRow }">
        <slot />
    </div>
</template>
