<script setup lang="ts">
import Button from '@app/components/ui/button/Button.vue'
import SaveButton from '@app/components/ui/button/SaveButton.vue'
import Dropdown from '@app/components/ui/dropdown/Dropdown.vue'
import Form from '@app/components/ui/form/Form.vue'
import FormActions from '@app/components/ui/form/FormActions.vue'
import FormField from '@app/components/ui/form/FormField.vue'
import FormSection from '@app/components/ui/form/FormSection.vue'
import { useModelSelect } from '@app/composables/useModelSelect'
import { useUserCan } from '@app/composables/useUserCan'
import type { Branch, ClientProject, LocationType } from '@app/types/shared'
import type { ShiftTemplate } from '@app/types/shifts'
import { TrashIcon } from '@heroicons/vue/24/solid'
import { router, useForm, usePage } from '@inertiajs/vue3'
import { trans } from 'laravel-vue-i18n'
import { computed } from 'vue'
import { route } from 'ziggy-js'

const { template } = defineProps<{
    template: ShiftTemplate | null
}>()

const emit = defineEmits(['submit'])

const { can } = useUserCan()

const branches = usePage().props.branches as Branch[]
const clientProjects = usePage().props.clientProjects as ClientProject[]
const locationTypes = usePage().props.locationTypes as LocationType[]

const selectedBranch = useModelSelect(branches, template?.branch_id)
const selectedClientProject = useModelSelect(clientProjects, template?.client_project_id)
const selectedLocationType = useModelSelect(locationTypes, template?.location_type_id)

const form = useForm({
    branch_id: template?.branch_id,
    location_type_id: template?.location_type_id,
    client_project_id: template?.client_project_id,
})

function submit() {
    if (template) {
        form.put(route('settings.shifts.template.update', template.id), { onSuccess: () => emit('submit') })
    } else {
        form.post(route('settings.shifts.template.store'), { onSuccess: () => emit('submit') })
    }
}

const formTitle = computed(() => (template ? trans('actions.update') : trans('actions.create')))

function deleteProject(id: number) {
    router.delete(route('settings.shifts.template.destroy', id))
}
</script>

<template>
    <Form :form="form" class="w-full" @submit="submit">
        <FormSection :title="formTitle">
            <FormField prop="client_project_id" class="col-span-12" :label="$t('attributes.client_project')">
                <Dropdown
                    :label="$t('attributes.client_project')"
                    :items="clientProjects"
                    propertyName="name"
                    v-model="selectedClientProject"
                    v-model:form="form.client_project_id"
                />
            </FormField>

            <FormField prop="branch_id" class="col-span-12" :label="$t('attributes.branch')">
                <Dropdown :label="$t('attributes.branch')" :items="branches" propertyName="name" v-model="selectedBranch" v-model:form="form.branch_id" />
            </FormField>

            <FormField prop="location_type_id" class="col-span-12" :label="$t('attributes.location_type')">
                <Dropdown
                    :label="$t('attributes.location_type')"
                    :items="locationTypes"
                    propertyName="name"
                    v-model="selectedLocationType"
                    v-model:form="form.location_type_id"
                />
            </FormField>
        </FormSection>

        <FormActions>
            <Button type="button" v-if="template && can('settings.shifts.template.destroy')" color="white" @click="deleteProject(template.id)">
                <TrashIcon class="size-4"></TrashIcon> {{ $t('actions.delete') }}
            </Button>
            <SaveButton :mode="template ? 'save' : 'create'" />
        </FormActions>
    </Form>
</template>
