<script setup lang="ts">
import ShiftAvailabilityEvent from '@app/components/shifts/availabilities/ShiftAvailabilityEvent.vue'
import Body from '@app/components/ui/calendar/body/Body.vue'
import TimeGrid from '@app/components/ui/calendar/body/TimeGrid.vue'
import TimeSlot from '@app/components/ui/calendar/body/TimeSlot.vue'
import Calendar from '@app/components/ui/calendar/Calendar.vue'
import Header from '@app/components/ui/calendar/header/Header.vue'
import WeekSwitcher from '@app/components/ui/calendar/header/WeekSwitcher.vue'
import { useWeekSwitcher } from '@app/composables/calendar/useWeekSwitcher'
import type { ShiftAvailability } from '@app/types/shifts'
import { eachDayOfInterval, endOfWeek, startOfWeek } from 'date-fns'
import { computed } from 'vue'

defineProps<{
    availabilities: ShiftAvailability[]
}>()

const { selectedDay, switchWeek } = useWeekSwitcher({ weekParamName: 'week', reloadOnly: ['availabilities'] })

const days = computed(() =>
    eachDayOfInterval({
        start: startOfWeek(selectedDay.value, { weekStartsOn: 1 }),
        end: endOfWeek(selectedDay.value, { weekStartsOn: 1 }),
    })
)
</script>

<template>
    <Calendar :days>
        <Header :date="selectedDay">
            <WeekSwitcher :model-value="selectedDay" @update:model-value="switchWeek" />
        </Header>

        <Body>
            <TimeGrid :start="9" :end="22">
                <TimeSlot v-for="availability in availabilities" :from="availability.from" :to="availability.to">
                    <ShiftAvailabilityEvent :availability />
                </TimeSlot>
            </TimeGrid>
        </Body>
    </Calendar>
</template>
