import { computed, type Ref } from 'vue'
import { route } from 'ziggy-js'

const settings = ['finance', 'general', 'recruitment', 'templates'] as const

export type SettingsGroup = (typeof settings)[number]

export function useSettingsTabs(currentRoute: Ref<string>, settingsGroup: SettingsGroup) {
    const generalSettingsTabs = computed(() => [
        {
            name: 'settings.role',
            href: route('settings.role.index'),
            active: currentRoute.value.startsWith('settings.role.'),
            permission: 'role.index',
        },
        {
            name: 'settings.branch',
            href: route('settings.branch.index'),
            active: currentRoute.value.startsWith('settings.branch'),
            permission: 'branch.index',
        },
        {
            name: 'settings.clients',
            href: route('settings.client.index'),
            active: currentRoute.value.startsWith('settings.client.'),
            permission: 'client.index',
        },
        {
            name: 'settings.client_projects',
            href: route('settings.client-project.index'),
            active: currentRoute.value.startsWith('settings.client-project'),
            permission: 'client-project.index',
        },
        {
            name: 'settings.location_types',
            href: route('settings.location-type.index'),
            active: currentRoute.value.startsWith('settings.location-type'),
            permission: 'location-type.index',
        },
        {
            name: 'settings.region',
            href: route('settings.region.index'),
            active: currentRoute.value.startsWith('settings.region'),
            permission: 'region.index',
        },
        {
            name: 'settings.job-title.header',
            href: route('settings.job-title.index'),
            active: currentRoute.value.startsWith('settings.job-title.'),
            permission: 'job-title.index',
        },
        {
            name: 'settings.job-title-external-application',
            href: route('settings.job-title-external-application.index'),
            active: currentRoute.value.startsWith('settings.job-title-external-application'),
            permission: 'job-title-external-application.index',
        },
        {
            name: 'settings.anonymize',
            href: route('settings.anonymize.index'),
            active: currentRoute.value.startsWith('settings.anonymize'),
            permission: 'anonymize.index',
        },
    ])

    const recruitmentSettingsTabs = computed(() => [
        {
            name: 'settings.channel',
            href: route('settings.recruitment.channel.index'),
            active: currentRoute.value.startsWith('settings.recruitment.channel'),
            permission: 'recruitment.channel.index',
        },
        {
            name: 'settings.marketing-channel',
            href: route('settings.recruitment.marketing-channel.index'),
            active: currentRoute.value.startsWith('settings.recruitment.marketing-channel'),
            permission: 'recruitment.marketing-channel.index',
        },
        {
            name: 'settings.recruitment-appointment-host',
            href: route('settings.recruitment.appointment-host.index'),
            active: currentRoute.value.startsWith('settings.recruitment.appointment-host'),
            permission: 'recruitment.appointment-host.index',
        },
    ])

    const templateSettingsTabs = computed(() => [
        {
            name: 'settings.whatsapp-template',
            href: route('settings.template.whatsapp.index'),
            active: currentRoute.value.startsWith('settings.template.whatsapp'),
            permission: 'whatsapp-template.index',
        },
        {
            name: 'settings.email-template',
            href: route('settings.template.email.index'),
            active: currentRoute.value.startsWith('settings.template.email'),
            permission: 'email-template.index',
        },
    ])

    const financeSettingsTabs = computed(() => [
        {
            name: 'settings.finance.minimum-wage',
            href: route('settings.finance.minimum-wage.index'),
            active: currentRoute.value.startsWith('settings.finance.minimum-wage'),
            permission: 'finance.minimum-wage.index',
        },
        {
            name: 'settings.finance.hourly-wage',
            href: route('settings.finance.hourly-wage.index'),
            active: currentRoute.value.startsWith('settings.finance.hourly-wage'),
            permission: 'finance.hourly-wage.index',
        },
        {
            name: 'settings.finance.shift-rank-bonus',
            href: route('settings.finance.shift-rank-bonus.index'),
            active: currentRoute.value.startsWith('settings.finance.shift-rank-bonus'),
            permission: 'finance.shift-rank-bonus.index',
        },
        {
            name: 'settings.finance.result-reward-donation',
            href: route('settings.finance.result-reward-donation.index'),
            active: currentRoute.value.startsWith('settings.finance.result-reward-donation'),
            permission: 'finance.result-reward-donation.index',
        },
        {
            name: 'settings.finance.result-reward-lottery',
            href: route('settings.finance.result-reward-lottery.index'),
            active: currentRoute.value.startsWith('settings.finance.result-reward-lottery'),
            permission: 'finance.result-reward-lottery.index',
        },
    ])

    const currentSettingsGroup = computed(() => getTabs(settingsGroup).value)

    function getTabs(group: SettingsGroup) {
        switch (group) {
            case 'finance':
                return financeSettingsTabs
            case 'recruitment':
                return recruitmentSettingsTabs
            case 'templates':
                return templateSettingsTabs
            default:
                return generalSettingsTabs
        }
    }

    return {
        currentSettingsGroup,
    }
}
