import { useModelSelect } from '@app/composables/useModelSelect'
import type { Funnel, FunnelBlock, Lead, RecruitmentAppointmentHost, RecruitmentAvailability } from '@app/types/recruitment'
import { sortBy, uniqBy } from 'lodash'
import { computed, type Ref } from 'vue'

export function useLeadRecruitmentAppointment(
    lead: Lead,
    funnel: Ref<Funnel>,
    funnelBlock: Ref<FunnelBlock>,
    appointmentHosts: Ref<RecruitmentAppointmentHost[]>
) {
    const missingLeadDetails = computed(() => !lead.email || !lead.phone_number)
    const isSalesAgentFunnel = computed(() => funnel.value.slug === 'sales-agent')
    const isWorkshopFunnelBlock = computed(() => funnelBlock.value.slug === 'workshop')
    const hasProjectsAndLocations = computed(() => isWorkshopFunnelBlock.value && isSalesAgentFunnel.value)

    const selectableBranches = computed(() => {
        const branches = appointmentHosts.value.map((host) => host.branch)

        return sortBy(uniqBy(branches, 'id'), 'name')
    })

    const selectedBranch = useModelSelect(selectableBranches, lead.branch.id, { autoselectOnChange: true })

    const hosts = computed(() => {
        return appointmentHosts.value.filter(
            (host) => host.funnel_id === funnel.value.id && host.branch_id === selectedBranch.value?.id && host.funnel_block_id === funnelBlock.value.id
        )
    })

    const projects = computed(() => {
        if (!isWorkshopFunnelBlock.value) return []

        const branchProjects = hosts.value.filter((host) => host.client_project !== null).map((host) => host.client_project)

        return uniqBy(branchProjects, 'id')
    })
    const selectedProject = useModelSelect(projects, projects.value[0]?.id, { autoselectOnChange: true })

    const locations = computed(() => {
        if (!hasProjectsAndLocations.value) return []

        const projectLocations = hosts.value
            .filter(({ location_type, client_project }) => location_type !== null && client_project?.id === selectedProject.value?.id)
            .map((host) => host.location_type)

        return uniqBy(projectLocations, 'id')
    })
    const selectedLocation = useModelSelect(locations, locations.value[0]?.id, { autoselectOnChange: true })

    const selectableHosts = computed(() => {
        if (isWorkshopFunnelBlock.value) {
            if (isSalesAgentFunnel.value) {
                return hosts.value.filter(
                    (host) => host.client_project.id === selectedProject.value?.id && host.location_type.id === selectedLocation.value?.id
                )
            }

            return hosts.value.filter((host) => host.client_project.id === selectedProject.value?.id)
        }

        return hosts.value
    })
    const selectedHost = useModelSelect(selectableHosts, selectableHosts.value[0]?.id, { autoselectOnChange: true })

    const recruiterAvailabilities = computed(() => {
        const selectedProjectId = selectedProject.value?.id || null
        const selectedLocationId = selectedLocation.value?.id || null

        const availabilities = selectedHost.value?.availabilities.filter(
            (availability) =>
                availability.funnel_id === funnel.value.id &&
                availability.funnel_block_id === funnelBlock.value.id &&
                availability.branch_id === selectedBranch.value?.id &&
                availability.client_project_id === selectedProjectId &&
                availability.location_type_id === selectedLocationId &&
                availability.has_slot_available &&
                lead.ongoing_recruitment_appointment?.recruitment_availability_id !== availability.id
        )

        return (
            availabilities?.sort(function (a: RecruitmentAvailability, b: RecruitmentAvailability) {
                return new Date(a.from).valueOf() - new Date(b.from).valueOf()
            }) || []
        )
    })

    const selectedRecruiterAvailability = useModelSelect(recruiterAvailabilities, recruiterAvailabilities.value[0]?.id, { autoselectOnChange: true })

    return {
        projects,
        missingLeadDetails,
        isSalesAgentFunnel,
        isWorkshopFunnelBlock,
        selectableBranches,
        selectedBranch,
        selectedProject,
        locations,
        selectedLocation,
        selectableHosts,
        selectedHost,
        recruiterAvailabilities,
        selectedRecruiterAvailability,
    }
}
